@import "styles/utils/mixins";

.item {
  position: relative;
  max-width: 570px;
  background-color: #fbfbff;
  border: 1px solid #efeff3;
  box-shadow: 0 4px 6px rgb(0 0 0 / 3%);
  cursor: pointer;
  margin: 10px 30px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 60px 31px;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.title {
  font-family: "TT Interfaces", sans-serif;
}

.subtitle {
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: #012f6b;
  opacity: 0.4;
}

.left {
  z-index: 1;
}

.location {
  position: relative;
  padding-right: 20px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background: #ce1050;
    border-radius: 50%;
  }

  &::after {
    transition: all 0.4s;
    content: " ";
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    right: 5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgb(206 16 80 / 30%);
    opacity: 0;
  }

  .item:hover &::after {
    opacity: 1;
  }
}

@include small-screen {
  .item {
    max-width: 100%;
    margin: 0 0 10px;
  }

  .wrapper {
    padding: 25px 20px 26px;
  }

  .title {
    margin-bottom: 6px;
  }

  .subtitle {
    font-size: 13px;
    line-height: 18px;
  }

  .location {
    padding-right: 0;

    &::before,
    &::after {
      content: none;
    }
  }
}
