@import "styles/utils/mixins";

.wrapper {
  padding: 85px 0;

  &.light {
    background-color: #fff;
  }

  &.grey {
    background-color: #efeff3;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  width: 50%;
}

.description {
  max-width: 450px;
}

.content {
  margin-top: 84px;
}

.items {
  margin: -10px -30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@include small-screen {
  .wrapper {
    padding: 65px 0;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  .content {
    margin-top: 38px;
  }

  .title {
    margin-bottom: 10px;
  }

  .description {
    max-width: 100%;
  }

  .items {
    margin: 0;
    grid-template-columns: 1fr;
  }
}
